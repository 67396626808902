import { useNavigate } from "react-router-dom";
import styles from "../../../Styles/custom.module.css";

type props = {
  head: { title: string }[];
  body: any[];
  onRowClick?: any;
  body_keys: string[];
  number?: boolean;
  action_list?: string[];
  action_handler?: any;
};

const Table = (props: props) => {
  const navigate = useNavigate();
  return (
    <div className={styles.table_container}>
      <table className={styles.table}>
        <thead>
          <tr>
            {props.head.map((val, index) => {
              return (
                <th align="left" key={index}>
                  {val.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.body.map((val, index) => {
            return (
              <tr key={index}>
                {props?.number && (
                  <td
                    onClick={() => {
                      if (props?.onRowClick) {
                        props.onRowClick(val);
                      }
                    }}
                    align="left"
                    style={
                      props?.onRowClick
                        ? { cursor: "pointer" }
                        : { cursor: "default" }
                    }
                  >
                    {index + 1}.
                  </td>
                )}

                {props.body_keys.map((valll, index) => {
                  return (
                    <td
                      key={index}
                      onClick={() => {
                        if (props?.onRowClick) {
                          props.onRowClick(val);
                        }
                      }}
                      align="left"
                      style={
                        props?.onRowClick
                          ? { cursor: "pointer" }
                          : { cursor: "default" }
                      }
                      // onClickCapture={(event) => {
                      // 	if (valll === "action") {
                      // 		event.stopPropagation();
                      // 		props.action_handler(val._id);
                      // 	}
                      // }}
                    >
                      {valll === "action" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            gap: "20px",
                          }}
                        >
                          <div
                            onClick={(event) => {
                              event.stopPropagation();
                              props.action_handler(val._id);
                            }}
                          >
                            🗑️
                          </div>
                          <div
                            onClick={(event) => {
                              event.stopPropagation();
                              props.onRowClick(val);
                            }}
                          >
                            📝
                          </div>
                        </div>
                      ) : (
                        val[valll]
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
