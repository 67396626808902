import React, { useEffect, useState } from "react";
import Content from "../../Components/Common/Content/Content";
import ContentHead from "../../Components/Common/Content/ContentHead";
import ContentBody from "../../Components/Common/Content/ContentBody";
import Table from "../../Components/Common/Table/Table";
import { brand_table_head } from "../../Utils/Data";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Pagination from "../../Components/Pagination";
import Modal from "../../Components/Modal";

const Brands = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [focus, setFocus] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      url: "https://backend.theholidayfeed.com/api/brands",
      method: "GET",
    })
      .then((res: any) => {
        console.log(res);
        let temp = res.data.brands;

        temp.sort((a: any, b: any) => {
          let a_name = a.brandName.toLowerCase(),
            b_name = b.brandName.toLowerCase();

          if (a_name > b_name) return 1;
          if (a_name < b_name) return -1;
          return 0;
        });

        setData(temp);
      })
      .catch((error: any) => {
        setData([]);
        toast.error(error.response.data.message);
      });
  };

  const handleDelete = (id: any) => {
    axios({
      url: "https://backend.theholidayfeed.com/api/brands/" + id,
      method: "DELETE",
    })
      .then((res: any) => {
        getData();
        toast.success("Brand deleted successfully");
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
      });
  };

  const handleAction = () => {
    // this.setState({ modal: false });
    setModal(false);
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: "https://backend.theholidayfeed.com/api/brands/" + focus,
          method: "delete",
          headers: {
            Authorization: localStorage.getItem("cpnblg_token"),
          },
        })
          .then((res: any) => {
            setFocus("");
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res: any) => {
          getData();
          return "Deleted Successfully!";
        },
        error: (err: any) => {
          return "Error in deleting the product.";
        },
      }
    );
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          // this.setState({ modal: false, focus: "" });
          setModal(false);
          setFocus("");
        }}
        onBtn2Click={handleAction}
      />
      <Content>
        <ContentHead
          title="Brands"
          showBtn1={true}
          btn1Name="btn1"
          btn1type="filled"
          btn1Text="Add Brand"
          onClickHandler={() => {
            navigate("/brand/new");
          }}
          // customElement={
          //   <>
          //     <Pagination
          //       pageNo={1}
          //       changePage={() => {}}
          //       total={10000}
          //       perPage={10000}
          //     />
          //   </>
          // }
        />
        <ContentBody>
          <Table
            head={brand_table_head}
            body={data}
            body_keys={["brandName", "url", "action"]}
            onRowClick={(val: any) => {
              navigate(`/brand/update/${val._id}`);
            }}
            number={true}
            action_handler={(id: any) => {
              setModal(true);
              setFocus(id);
            }}
          />
        </ContentBody>
      </Content>
    </>
  );
};

export default Brands;
