import React, { Component } from "react";
import Content from "../../../Components/Common/Content/Content";
import ContentHead from "../../../Components/Common/Content/ContentHead";
import ContentBody from "../../../Components/Common/Content/ContentBody";
import Input from "../../../Components/Common/Custom/Input";
import TextEditor from "../../../Components/Common/TextEditor/TextEditor";
import InputField from "../../../Components/Common/Custom/InputField";
import withRouter from "../../../Utils/withRouter";
import callApi from "../../../Apis/callApi";
import UploadImage from "../../../Utils/UploadImage";
import { Country, City } from "country-state-city";
import axios from "axios";
import { toast } from "react-hot-toast";
import { animal_dropdown, categories } from "../../../Utils/Data";
import { getExtendedFileName } from "../../../Utils/ImageHelper";

class CouponDetails extends Component<any, any> {
  state = {
    id: "",
    imageUrl: "",
    imageObject: "",
    title: "",
    offerUrl: "",
    companyUrl: "",
    companyLogoUrl: "",
    companyLogoObject: "",
    companyName: "",
    category: "",
    subCategory: "",
    couponType: "",
    dealType: "",
    validity: "",
    content: "",
    shortDescription: "",
    loading: false,
    folderModal: false,
    imageChanged: false,
    logoimageChange: false,
    couponCode: "",
    brands: [],
    brand: "",
    couponSequence: "",
  };

  componentDidMount(): void {
    this.getBrands();
  }

  getBrands = () => {
    this.setState({ loading: true });
    axios({
      url: "https://backend.theholidayfeed.com/api/brands/",
      method: "get",
    })
      .then((res: any) => {
        this.setState({ brands: res.data.brands });
        this.setData();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  setData = () => {
    axios({
      method: "get",
      url: `https://backend.theholidayfeed.com/api/coupons/${this.props.router.params.id}`,
    })
      .then((res) => {
        let data = res.data.coupon;

        const imgRequest = JSON.stringify({
          bucket: process.env.REACT_APP_BUCKET_NAME,
          key: "Coupons/" + data.couponImage,
          edits: {
            formats: "webp",
          },
        });

        const imgRequest2 = JSON.stringify({
          bucket: process.env.REACT_APP_BUCKET_NAME,
          key: "Coupons/" + data.compLogo,
          edits: {
            formats: "webp",
          },
        });

        this.setState({
          id: data._id,
          title: data?.title ? data.title : "",
          couponType: data?.couponType ? data?.couponType : "",
          category: data?.category ? data?.category : "",
          subCategory: data?.subCategory ? data?.subCategory : "",
          shortDescription: data?.shortDescription ? data.shortDescription : "",
          content: data?.longDescription ? data?.longDescription : "",
          dealType: data?.dealType ? data.dealType : "",
          companyUrl: data?.compwebsiteUrl ? data.compwebsiteUrl : "",
          offerUrl: data?.offerUrl ? data.offerUrl : "",
          companyName: data?.companyName ? data.companyName : "",
          validity: data?.validity ? data.validity.split("T")[0] : "",
          imageUrl: data?.couponImage ? data?.couponImage : "",
          companyLogoUrl: data?.compLogo ? data?.compLogo : "",
          couponCode: data?.couponCode ? data?.couponCode : "",
          brand: data?.brandId,
          couponSequence: data?.couponSequence ? data?.couponSequence : "",
        });

        this.setState({ loading: false });
      })
      .catch((errror) => {
        toast.error("Something went wrong in fetching blog data.");
        this.setState({ loading: false });
      });
  };

  handleImage = (event: any) => {
    this.setState({
      imageUrl: URL.createObjectURL(event.target.files[0]),
      imageObject: event.target.files[0],
      imageChanged: true,
    });
  };

  handleLogoImage = (event: any) => {
    this.setState({
      companyLogoUrl: URL.createObjectURL(event.target.files[0]),
      companyLogoObject: event.target.files[0],
      logoimageChange: true,
    });
  };

  validate = () => {
    return true;
    let text = "";

    // check image
    if (!this.state.imageUrl && !text) {
      text = "Banner image";
    }

    // check title
    if (!this.state.title && !text) {
      text = "Blog title";
    }

    // short description
    if (!this.state.shortDescription && !text) {
      text = "Short description";
    }

    // blog content
    if (!this.state.content && !text) {
      text = "Blog content";
    }

    if (!!text) {
      toast.error(`${text} is Required.`);
      return false;
    }

    return true;
  };

  removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  handleSubmit = (event: any) => {
    if (event.target.name === "btn2") {
      this.props.router.navigate("/coupons");
    } else {
      if (this.validate()) {
        if (this.state.imageObject) {
          // means coupon image is changed

          if (this.state.companyLogoObject) {
            // means company logo is changed an uploaded from the local storage

            this.setState({ loading: true });

            let timestamp = new Date();

            let filename = getExtendedFileName(this.state.imageObject);

            UploadImage({
              file: this.state.imageObject,
              name: "Coupons/" + filename,
            })
              .then((res) => {
                let timestamp = new Date();

                let logo_filename = getExtendedFileName(
                  this.state.companyLogoObject
                );

                UploadImage({
                  file: this.state.companyLogoObject,
                  name: "Coupons/" + logo_filename,
                })
                  .then((r) => {
                    let data: any = {
                      title: this.state.title,
                      couponType: this.state.couponType,
                      category: this.state.category,
                      subCategory: this.state.subCategory,
                      shortDescription: this.state.shortDescription,
                      longDescription: this.state.content,
                      dealType: this.state.dealType,
                      offerUrl: this.state.offerUrl,
                      compwebsiteUrl: this.state.companyUrl,
                      couponImage: filename,
                      compLogo: logo_filename,
                      companyName: this.state.companyName,
                      validity: this.state.validity,
                      brandId: this.state.brand,
                    };

                    if (!data.brandId) {
                      console.log("deleting it");
                      delete data.brandId;
                    }

                    if (!data.couponType) {
                      console.log("deleting it");
                      delete data.couponType;
                    }

                    if (!data.category) {
                      console.log("deleting it");
                      delete data.category;
                    }

                    if (!data.dealType) {
                      console.log("deleting it");
                      delete data.dealType;
                    }

                    callApi({ url: this.state.id, method: "patch", data })
                      .then((response) => {
                        this.setState({ loading: false });
                        toast.success("Coupon Updated Successfully");
                        this.props.router.navigate("/coupons");
                      })
                      .catch((error) => {
                        this.setState({ loading: false });
                        toast.error(
                          "Something went wrong please try again later."
                        );
                      });
                  })
                  .catch((e) => {
                    this.setState({ loading: false });
                    toast.error(
                      "Something went wrong while uploading logo image."
                    );
                  });
              })
              .catch((error) => {
                this.setState({ loading: false });
                toast.error(
                  "Something went wrong while uploading coupon image."
                );
              });
          } else {
            // means company logo is not uploaded from local storage but might be changed
            this.setState({ loading: true });

            let timestamp = new Date();

            let filename = getExtendedFileName(this.state.imageObject);

            UploadImage({
              file: this.state.imageObject,
              name: "Coupons/" + filename,
            })
              .then((res) => {
                let timestamp = new Date();

                let data: any = {
                  title: this.state.title,
                  couponType: this.state.couponType,
                  category: this.state.category,
                  subCategory: this.state.subCategory,
                  shortDescription: this.state.shortDescription,
                  longDescription: this.state.content,
                  dealType: this.state.dealType,
                  offerUrl: this.state.offerUrl,
                  compwebsiteUrl: this.state.companyUrl,
                  couponImage: filename,
                  compLogo: this.state.companyLogoUrl,
                  companyName: this.state.companyName,
                  validity: this.state.validity,
                  brandId: this.state.brand,
                };

                if (!data.brandId) {
                  console.log("deleting it");
                  delete data.brandId;
                }

                if (!data.couponType) {
                  console.log("deleting it");
                  delete data.couponType;
                }

                if (!data.category) {
                  console.log("deleting it");
                  delete data.category;
                }

                if (!data.dealType) {
                  console.log("deleting it");
                  delete data.dealType;
                }

                callApi({ url: this.state.id, method: "patch", data })
                  .then((response) => {
                    this.setState({ loading: false });
                    toast.success("Coupon Updated Successfully");
                    this.props.router.navigate("/coupons");
                  })
                  .catch((error) => {
                    this.setState({ loading: false });
                    toast.error("Something went wrong please try again later.");
                  });
              })
              .catch((error) => {
                this.setState({ loading: false });
                toast.error(
                  "Something went wrong while uploading coupon image."
                );
              });
          }
        } else {
          // means coupon image is not changed
          if (this.state.companyLogoObject) {
            // means company logo is changed an uploaded from the local storage

            this.setState({ loading: true });

            let timestamp = new Date();

            let logo_filename = getExtendedFileName(
              this.state.companyLogoObject
            );

            UploadImage({
              file: this.state.companyLogoObject,
              name: "Coupons/" + logo_filename,
            })
              .then((r) => {
                let data: any = {
                  title: this.state.title,
                  couponType: this.state.couponType,
                  category: this.state.category,
                  subCategory: this.state.subCategory,
                  shortDescription: this.state.shortDescription,
                  longDescription: this.state.content,
                  dealType: this.state.dealType,
                  offerUrl: this.state.offerUrl,
                  compwebsiteUrl: this.state.companyUrl,
                  couponImage: this.state.imageUrl,
                  compLogo: logo_filename,
                  companyName: this.state.companyName,
                  validity: this.state.validity,
                  brandId: this.state.brand,
                };

                if (!data.brandId) {
                  console.log("deleting it");
                  delete data.brandId;
                }

                if (!data.couponType) {
                  console.log("deleting it");
                  delete data.couponType;
                }

                if (!data.category) {
                  console.log("deleting it");
                  delete data.category;
                }

                if (!data.dealType) {
                  console.log("deleting it");
                  delete data.dealType;
                }

                callApi({ url: this.state.id, method: "patch", data })
                  .then((response) => {
                    this.setState({ loading: false });
                    toast.success("Coupon Updated Successfully");
                    this.props.router.navigate("/coupons");
                  })
                  .catch((error) => {
                    this.setState({ loading: false });
                    toast.error("Something went wrong please try again later.");
                  });
              })
              .catch((e) => {
                this.setState({ loading: false });
                toast.error("Something went wrong while uploading logo image.");
              });
          } else {
            // means company logo is not uploaded from local storage but might be changed
            this.setState({ loading: true });

            let data: any = {
              title: this.state.title,
              couponType: this.state.couponType,
              category: this.state.category,
              subCategory: this.state.subCategory,
              shortDescription: this.state.shortDescription,
              longDescription: this.state.content,
              dealType: this.state.dealType,
              offerUrl: this.state.offerUrl,
              compwebsiteUrl: this.state.companyUrl,
              couponImage: this.state.imageUrl,
              compLogo: this.state.companyLogoUrl,
              companyName: this.state.companyName,
              validity: this.state.validity,
              brandId: this.state.brand,
            };

            if (!data.brandId) {
              console.log("deleting it");
              delete data.brandId;
            }

            if (!data.couponType) {
              console.log("deleting it");
              delete data.couponType;
            }

            if (!data.category) {
              console.log("deleting it");
              delete data.category;
            }

            if (!data.dealType) {
              console.log("deleting it");
              delete data.dealType;
            }

            callApi({ url: this.state.id, method: "patch", data })
              .then((response) => {
                this.setState({ loading: false });
                toast.success("Coupon Updated Successfully");
                this.props.router.navigate("/coupons");
              })
              .catch((error) => {
                this.setState({ loading: false });
                toast.error("Something went wrong please try again later.");
              });
          }
        }
      }
    }
  };

  render() {
    return (
      <Content>
        <ContentHead
          title="Edit Deal"
          showBtn1={true}
          showBtn2={true}
          btn1type="filled"
          btn2type="outlined"
          btn1Name="btn1"
          btn2Name="btn2"
          btn1Text="Save"
          btn2Text="Discard"
          onClickHandler={this.handleSubmit}
        />
        <ContentBody>
          <>
            <div style={{ marginBottom: "20px", color: "grey" }}>
              Deal Sequence : {this.state.couponSequence}
            </div>
            <div
              style={{
                position: "absolute",
                height: "100vh",
                width: "100vw",
                background: "rgba(0,0,0,.3)",
                top: 0,
                left: 0,
                display: this.state.loading ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <div className={"spin"}></div>
            </div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                transform: "translateX(5px)",
                marginBottom: "10px",
              }}
            >
              Deal Image
              {this.state.imageUrl && (
                <span
                  style={{
                    display: "inline-block",
                    background: "grey",
                    borderRadius: "10px",
                    marginLeft: "10px",
                    padding: "10px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ imageUrl: "", imageObject: "" });
                  }}
                >
                  Remove
                </span>
              )}
            </p>

            {!this.state.imageUrl ? (
              <div
                style={{
                  height: "50px",
                  width: "50px",
                  textAlign: "center",
                  background: "transparent",
                  border: "1px dashed grey",
                  borderRadius: "10px",
                  margin: "10px",
                  cursor: "pointer",
                  position: "relative",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                +
                <input
                  type="file"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    cursor: "pointer",
                  }}
                  accept="image/*"
                  onChange={this.handleImage}
                ></input>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  aspectRatio: "16/9",
                  backgroundImage: `url('${
                    this.state.imageChanged
                      ? this.state.imageUrl
                      : process.env.REACT_APP_IMAGE_BASE_FAST_LINK +
                        "Coupons/" +
                        this.state.imageUrl
                  }')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  marginBottom: "40px",
                  marginTop: "10px",
                  borderRadius: "15px",
                  position: "relative",
                }}
              ></div>
            )}

            <InputField
              isLabel={true}
              value={this.state.title}
              lable="Title"
              type="text"
              isRequired={false}
              placeholder="Blog Heading"
              onChange={(event: any) => {
                this.setState({ title: event.target.value });
              }}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <InputField
                isLabel={true}
                value={this.state.offerUrl}
                lable="Offer Url"
                type="text"
                isRequired={false}
                placeholder="Blog Heading"
                onChange={(event: any) => {
                  this.setState({ offerUrl: event.target.value });
                }}
              />
              <InputField
                isLabel={true}
                value={this.state.brand}
                lable="Select Brand"
                type="select"
                options={[...this.state.brands]}
                accessText="brandName"
                accessValue="_id"
                isRequired={false}
                placeholder="Enter Companies website url"
                onChange={(event: any) => {
                  this.setState({ brand: event.target.value });
                }}
              />
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <InputField
                type="select"
                isLabel={true}
                value={this.state.category}
                lable="Category"
                isRequired={false}
                placeholder="Category"
                options={Object.keys(categories).map((val) => ({
                  name: val,
                  value: val,
                }))}
                accessText={"name"}
                accessValue={"value"}
                onChange={(event: any) => {
                  this.setState({
                    category: event.target.value,
                  });
                }}
              />
              {this.state.category && (
                <InputField
                  type="select"
                  isLabel={true}
                  value={this.state.subCategory}
                  lable="Sub Category"
                  isRequired={false}
                  placeholder="Sub Category"
                  options={
                    // @ts-ignore
                    categories[this.state.category]
                      ? // @ts-ignore
                        categories[this.state.category].map((val) => ({
                          name: val,
                          value: val,
                        }))
                      : []
                  }
                  accessText="name"
                  accessValue="value"
                  onChange={(e: any) => {
                    this.setState({ subCategory: e.target.value });
                  }}
                />
              )}
              <InputField
                type="select"
                isLabel={true}
                value={this.state.couponType}
                lable="Deal Location"
                isRequired={false}
                placeholder="Deal Location"
                options={[
                  { name: "Hero", value: "hero" },
                  { name: "Deal of the day", value: "dealoftheday" },
                  { name: "Trending", value: "trending" },
                  { name: "Preferred", value: "preferred" },
                ]}
                accessText="name"
                accessValue="value"
                onChange={(event: any) => {
                  this.setState({
                    couponType: event.target.value,
                  });
                }}
              />
              {/* </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            > */}
              <InputField
                type="select"
                isLabel={true}
                value={this.state.dealType}
                lable="Deal Type"
                isRequired={false}
                placeholder="Deal Type"
                options={[
                  { name: "Offer", value: "offer" },
                  { name: "Coupon", value: "coupon" },
                ]}
                accessText={"name"}
                accessValue={"value"}
                onChange={(event: any) => {
                  this.setState({
                    dealType: event.target.value,
                  });
                }}
              />
              <InputField
                type="date"
                isLabel={true}
                value={this.state.validity}
                lable="Validity Date"
                isRequired={false}
                placeholder="Blog Heading"
                options={animal_dropdown}
                accessText={"name"}
                accessValue={"value"}
                onChange={(event: any) => {
                  this.setState({
                    validity: event.target.value,
                  });
                }}
              />
              {/* </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            > */}
              <InputField
                isLabel={true}
                value={this.state.couponCode}
                lable="Deal Code"
                type="text"
                isRequired={true}
                placeholder="Deal Code"
                onChange={(event: any) => {
                  this.setState({ couponCode: event.target.value });
                }}
              />
            </div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                transform: "translateX(5px)",
                marginTop: "20px",
              }}
            >
              Short Description
            </p>
            <textarea
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "20px",
                borderRadius: "15px",
                border: "1px solid black",
                padding: "20px",
                height: "100px",
              }}
              value={this.state.shortDescription}
              onChange={(event: any) => {
                this.setState({ shortDescription: event.target.value });
              }}
            />
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                transform: "translateX(5px)",
                marginBottom: "10px",
              }}
            >
              Long Description
            </p>
            <TextEditor
              content={this.state.content}
              handleChange={(
                value: any,
                delta: any,
                source: any,
                editor: any
              ) => {
                console.log(value);
                this.setState({ content: value });
              }}
            />
          </>
        </ContentBody>
      </Content>
    );
  }
}

export default withRouter(CouponDetails);
