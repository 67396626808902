import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "./App";
import * as Pages from "./pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <h1>Error Occured</h1>,
    children: [
      { index: true, element: <Navigate to="/coupons" replace /> },
      {
        path: "/coupons",
        element: <Pages.Coupons />,
      },
      {
        path: "/coupons/create_new_coupon",
        element: <Pages.CreateNewCoupon />,
      },
      { path: "/coupon_details/:id", element: <Pages.CouponDetails /> },
      {
        path: "/blogs",
        element: <Pages.Blogs />,
      },
      { path: "/blogs/create_new_blog", element: <Pages.CreateNewBlog /> },
      { path: "/blogs_details/:id", element: <Pages.BlogDetails /> },
      { path: "/ads", element: <Pages.Ad /> },
      { path: "/ads/create_new_ad", element: <Pages.CreateNewAd /> },
      { path: "/ads/update-ad/:id", element: <Pages.UpdateAd /> },
      // brands
      { path: "/brands", element: <Pages.Brands /> },
      { path: "/brand/new", element: <Pages.AddUpdateBrands /> },
      { path: "/brand/update/:id", element: <Pages.AddUpdateBrands /> },
    ],
  },
]);

export default router;
