import React, { Component } from "react";
import Content from "../../../Components/Common/Content/Content";
import ContentHead from "../../../Components/Common/Content/ContentHead";
import ContentBody from "../../../Components/Common/Content/ContentBody";
import Input from "../../../Components/Common/Custom/Input";
import TextEditor from "../../../Components/Common/TextEditor/TextEditor";
import InputField from "../../../Components/Common/Custom/InputField";
import withRouter from "../../../Utils/withRouter";
import callApi from "../../../Apis/callApi";
import UploadImage from "../../../Utils/UploadImage";
import { Country, City } from "country-state-city";
import axios from "axios";
import { toast } from "react-hot-toast";
import { animal_dropdown } from "../../../Utils/Data";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import styles from "../CreateNewBlog/cnb.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

class BlogDetails extends Component<any, any> {
  state = {
    imageUrl: "",
    imageObject: "",
    content: "",
    title: "",
    animal: "",
    shortDescription: "",
    blogType: "",
    blogCategory: "",
    loading: false,
    imageChanged: false,
    ads: [],
    selectedAds: [],
  };

  componentDidMount(): void {
    this.getAds();
  }

  getAds = () => {
    this.setState({ loading: true });
    axios({
      url: "https://backend.theholidayfeed.com/api/advertisements/?identifier=thecouponsfeed.com",
      method: "get",
    })
      .then((res) => {
        this.setState({ ads: res.data.advertisements });
        this.setData();
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  setData = () => {
    axios({
      method: "GET",
      url: `https://backend.theholidayfeed.com/api/couponblogs/${this.props.router.params.id}`,
    })
      .then((res) => {
        let data = res.data.couponBlog;

        let ads = data.ads;
        let temp_ads = this.state.ads;
        let final_selected: any = [];
        let final_ads = this.state.ads;

        for (let i = 0; i < ads.length; i++) {
          for (let j = 0; j < temp_ads.length; j++) {
            // @ts-ignore
            if (ads[i] === temp_ads[j]._id) {
              final_selected.push(temp_ads[j]);
              final_ads = final_ads.filter((val: any) => {
                // @ts-ignore
                if (val._id === temp_ads[j]._id) {
                  return false;
                }
                return true;
              });
            }
          }
        }

        this.setState({
          title: data.title,
          animal: data.animal,
          shortDescription: data.shortDescription,
          content: data.description,
          imageUrl: data.images[0],
          blogType: data.blogType,
          blogCategory: data.blogCategory,
          selectedAds: final_selected,
          ads: final_ads,
          loading: false,
        });
      })
      .catch((errror) => {
        console.log(errror.response.data.message);
        toast.error(errror.response.data.message);
        this.setState({ loading: false });
      });
  };

  handleImage = (event: any) => {
    this.setState({
      imageUrl: URL.createObjectURL(event.target.files[0]),
      imageObject: event.target.files[0],
      imageChanged: true,
    });
  };

  validate = () => {
    return true;
  };

  removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  handleSubmit = (event: any) => {
    if (event.target.name === "btn2") {
      this.props.router.navigate("/blogs");
    } else {
      if (this.validate()) {
        if (this.state.imageChanged) {
          this.setState({ loading: true });
          this.setState({ loading: true });

          let timestamp = new Date();
          let filename = getExtendedFileName(this.state.imageObject);

          UploadImage({ file: this.state.imageObject, name: filename })
            .then(() => {
              let image_url = filename;

              console.log(this.state.selectedAds);

              let data = {
                title: this.state.title,
                description: this.state.content,
                shortDescription: this.state.shortDescription,
                images: image_url,
                ads: this.state.selectedAds.map((val: any) => {
                  return val._id;
                }),
              };

              axios({
                url:
                  "https://backend.theholidayfeed.com/api/couponblogs/" +
                  this.props.router.params.id,
                data,
                method: "patch",
                headers: {
                  Authorization: localStorage.getItem("cpnblg_token"),
                },
              })
                .then(() => {
                  this.setState({ loading: false });
                  this.props.router.navigate("/blogs");
                  toast.success("Updated Successfully!");
                })
                .catch((err) => {
                  this.setState({ loading: false });
                  toast.error(err.response.data.message);
                });
            })
            .catch();
        } else {
          this.setState({ loading: true });
          let data = {
            title: this.state.title,
            animal: this.state.animal,
            description: this.state.content,
            shortDescription: this.state.shortDescription,
            blogType: this.state.blogType,
            blogCategory: this.state.blogCategory,
            images: this.state.imageUrl,
            banner: "false",
            ads: this.state.selectedAds.map((val: any) => {
              return val._id;
            }),
          };

          axios({
            url:
              "https://backend.theholidayfeed.com/api/couponblogs/" +
              this.props.router.params.id,
            data,
            method: "patch",
            headers: {
              Authorization: localStorage.getItem("cpnblg_token"),
            },
          })
            .then(() => {
              this.setState({ loading: false });
              this.props.router.navigate("/blogs");
              toast.success("Updated Successfully!");
            })
            .catch(() => {
              this.setState({ loading: false });
              toast.error("Something went wrong!");
            });
        }
      }
    }
  };

  handleAdsChange = (event: any) => {
    if (
      this.state.selectedAds.length === 6 ||
      this.state.selectedAds.length >= 6
    ) {
      toast.error("You can only add 6 ads per blog.");
    } else {
      let selectedAds = this.state.selectedAds;
      let ads = this.state.ads;

      let ad: any = ads.find((val: any) => val._id === event.target.value);

      if (ad) {
        // @ts-ignore
        selectedAds.push(ad);

        for (let i = 0; i < ads.length; i++) {
          // @ts-ignore
          if (ads[i]._id === ad._id) {
            ads.splice(i, 1);
            break;
          }
        }
      }

      this.setState({ selectedAds });
      this.setState({ ads });
    }
  };

  handleSelectedAdsRemove = (index: any) => {
    let selectedAds = this.state.selectedAds;
    let ads = this.state.ads;
    let ad = selectedAds[index];
    if (ad !== -1) {
      ads.push(ad);
      selectedAds.splice(index, 1);
    }
    this.setState({ selectedAds });
    this.setState({ ads });
  };

  render() {
    return (
      <Content>
        <ContentHead
          title="Edit Blog"
          showBtn1={true}
          showBtn2={true}
          btn1type="filled"
          btn2type="outlined"
          btn1Name="btn1"
          btn2Name="btn2"
          btn1Text="Save"
          btn2Text="Discard"
          onClickHandler={this.handleSubmit}
        />
        <ContentBody>
          <>
            <div
              style={{
                position: "absolute",
                height: "100vh",
                width: "100vw",
                background: "rgba(0,0,0,.3)",
                top: 0,
                left: 0,
                display: this.state.loading ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <div className={"spin"}></div>
            </div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                transform: "translateX(5px)",
                marginBottom: "10px",
              }}
            >
              Banner Image
              {this.state.imageUrl && (
                <span
                  style={{
                    display: "inline-block",
                    background: "grey",
                    borderRadius: "10px",
                    marginLeft: "10px",
                    padding: "10px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ imageUrl: "", imageObject: "" });
                  }}
                >
                  Remove
                </span>
              )}
            </p>

            {!this.state.imageUrl ? (
              <div
                style={{
                  height: "50px",
                  width: "50px",
                  textAlign: "center",
                  background: "transparent",
                  border: "1px dashed grey",
                  borderRadius: "10px",
                  margin: "10px",
                  cursor: "pointer",
                  position: "relative",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                +
                <input
                  type="file"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                  }}
                  accept="image/*"
                  onChange={this.handleImage}
                ></input>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  aspectRatio: "16/9",
                  backgroundImage: `url('${
                    this.state.imageChanged
                      ? this.state.imageUrl
                      : process.env.REACT_APP_IMAGE_BASE_FAST_LINK +
                        this.state.imageUrl
                  }')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  marginBottom: "40px",
                  marginTop: "10px",
                  borderRadius: "15px",
                  position: "relative",
                }}
              ></div>
            )}

            <InputField
              isLabel={true}
              value={this.state.title}
              lable="Blog Title"
              type="text"
              isRequired={false}
              placeholder="Blog Heading"
              onChange={(event: any) => {
                this.setState({ title: event.target.value });
              }}
            />

            {/* <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <InputField
                type="select"
                isLabel={true}
                value={this.state.animal}
                lable="Animal"
                isRequired={false}
                placeholder="Blog Heading"
                options={animal_dropdown}
                accessText={"name"}
                accessValue={"value"}
                onChange={(event: any) => {
                  this.setState({
                    animal: event.target.value,
                  });
                }}
              />
            </div> */}
            {/* <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <InputField
                type="select"
                isLabel={true}
                value={this.state.blogCategory}
                lable="Category"
                isRequired={false}
                placeholder="Blog Heading"
                options={[
                  { name: "Hero", value: "hero" },
                  { name: "Discover 1", value: "discover-1" },
                  { name: "Discover 2", value: "discover-2" },
                  { name: "Normal", value: "normal" },
                ]}
                accessText={"name"}
                accessValue={"value"}
                onChange={(event: any) => {
                  this.setState({
                    blogCategory: event.target.value,
                  });
                }}
              />
              <InputField
                type="select"
                isLabel={true}
                value={this.state.blogType}
                lable="Type"
                isRequired={false}
                placeholder="Blog Heading"
                options={[
                  { name: "Featured", value: "featured" },
                  { name: "Trending", value: "trending" },
                  { name: "Regular", value: "regular" },
                ]}
                accessText="name"
                accessValue="value"
                onChange={(event: any) => {
                  this.setState({
                    blogType: event.target.value,
                  });
                }}
              />
            </div> */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <InputField
                type="select"
                isLabel={true}
                value={""}
                lable="Advertisement (6 max)"
                isRequired={false}
                placeholder="Ads"
                options={this.state.ads}
                accessText="title"
                accessValue="_id"
                onChange={this.handleAdsChange}
              />
            </div>
            <div className={styles.ads_container}>
              {this.state.selectedAds.map((val: any, index) => {
                return (
                  <div className={styles.ad} key={index}>
                    <div
                      className={styles.ad_image}
                      style={{
                        backgroundImage: `url('${
                          process.env.REACT_APP_IMAGE_BASE_FAST_LINK +
                          val?.couponImage
                        }')`,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                    <p className={styles.ad_title}>{val.title}</p>
                    <FontAwesomeIcon
                      icon={faClose}
                      color="white"
                      className={styles.ad_close}
                      onClick={() => {
                        this.handleSelectedAdsRemove(index);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                transform: "translateX(5px)",
                marginTop: "20px",
              }}
            >
              Short Description
            </p>
            <textarea
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "20px",
                borderRadius: "15px",
                border: "1px solid black",
                padding: "20px",
                height: "100px",
              }}
              value={this.state.shortDescription}
              onChange={(event: any) => {
                this.setState({ shortDescription: event.target.value });
              }}
            />
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                transform: "translateX(5px)",
                marginBottom: "10px",
              }}
            >
              Blog Content
            </p>
            <TextEditor
              content={this.state.content}
              handleChange={(value: any) => {
                console.log(value);
                this.setState({ content: value });
              }}
            />
          </>
        </ContentBody>
      </Content>
    );
  }
}

export default withRouter(BlogDetails);
