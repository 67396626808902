import React, { Component } from "react";
import Content from "../../Components/Common/Content/Content";
import ContentHead from "../../Components/Common/Content/ContentHead";
import ContentBody from "../../Components/Common/Content/ContentBody";
import Table from "../../Components/Common/Table/Table";
import { blog_table_head } from "../../Utils/Data";
import withRouter from "../../Utils/withRouter";
import { Outlet } from "react-router-dom";
import axios from "axios";
import callApi from "../../Apis/callApi";
import { toast } from "react-hot-toast";
import DraggableTable from "../../Components/DraggableTable";
import Modal from "../../Components/Modal";

class Blogs extends Component<any, any> {
  state = {
    path_on: "",
    blogs: [],
    index1: 0,
    index2: 0,
    modal: false,
    focus: "",
  };

  getData = () => {
    axios({
      method: "get",
      url: "https://backend.theholidayfeed.com/api/couponblogs/",
    })
      .then((res) => {
        console.log(res);
        this.setState({
          blogs: [
            ...res.data.couponBlogs.sort(
              (a: any, b: any) => a.priority - b.priority
            ),
          ],
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ blogs: [] });
      });
  };

  componentDidMount(): void {
    let x = this.props.router.location.pathname.split("/");
    x = x[x.length - 1];

    this.setState({ path_on: x });
    this.getData();
  }

  handleSwap = (id1: any, id2: any, priority1: any, priority2: any) => {
    let temp: any = [...this.state.blogs];

    let index1 = temp.findIndex((val: any) => val._id === id1);

    let index2 = temp.findIndex((val: any) => val._id === id2);

    console.log("index of 1st : ", index1);
    console.log("index of second : ", index2);

    console.log(priority1);
    console.log(priority2);

    this.setState({ index1: index1, index2: index2 }, () => {
      if (priority1 > priority2) {
        temp[index1].priority = priority2;
        // means picked from bottom to the top position
        for (let i = index2; i < index1; i++) {
          temp[i].priority = temp[i].priority + 1;
        }
      }

      if (priority2 > priority1) {
        // means picked from the top to bottom position
        temp[index1].priority = priority2;

        for (let i = index1 + 1; i < index2 + 1; i++) {
          temp[i].priority = temp[i].priority - 1;
        }
      }

      this.setState(
        {
          blogs: temp.sort((a: any, b: any) => a.priority - b.priority),
          loading: true,
        },
        () => {
          this.updateForSwap();
        }
      );
    });
  };

  updateForSwap = async () => {
    console.log(
      "in update for swap ",
      this.state.index1,
      this.state.index2,
      this.state.blogs
    );
    // console.log("in update for swap ", this.state.index1, this.state.index2);

    if (this.state.index1 > this.state.index2) {
      for (let i = this.state.index2; i < this.state.index1 + 1; i++) {
        let temp = axios({
          url:
            "https://backend.theholidayfeed.com/api/couponblogs/" +
            // @ts-ignore
            this.state.blogs[i]._id,
          method: "PATCH",
          // @ts-ignore
          data: { priority: this.state.blogs[i].priority },
        });

        console.log("temp - ", i);
      }

      console.log("refetching*******");

      this.getData();
    }

    if (this.state.index2 > this.state.index1) {
      for (let i = this.state.index1; i < this.state.index2 + 1; i++) {
        let temp = await axios({
          // @ts-ignore
          url:
            "https://backend.theholidayfeed.com/api/couponblogs/" +
            // @ts-ignore
            this.state.blogs[i]._id,
          // @ts-ignore
          data: { priority: this.state.blogs[i].priority },
          method: "patch",
        });

        console.log("temp - ", i);
      }

      console.log("refetching*******");

      this.getData();
    }
  };

  handleAction = () => {
    this.setState({ modal: false });
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url:
            "https://backend.theholidayfeed.com/api/couponblogs/" +
            this.state.focus,
          method: "delete",
        })
          .then((res: any) => {
            this.setState({ focus: "" });
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res: any) => {
          this.getData();
          return "Deleted Successfully!";
        },
        error: (err: any) => {
          return "Error in deleting the product.";
        },
      }
    );
  };

  render() {
    return (
      <>
        <Modal
          title={"Delete"}
          description={
            "Do you really want to delete this record? This process cannot be undone."
          }
          state={this.state.modal}
          onBtn1Click={() => {
            this.setState({ modal: false, focus: "" });
          }}
          onBtn2Click={this.handleAction}
        />
        <Content>
          <ContentHead
            title="Blogs"
            showBtn1={true}
            btn1Name="btn1"
            btn1type="filled"
            btn1Text="Add Blog"
            onClickHandler={() => {
              this.props.router.navigate("/blogs/create_new_blog");
            }}
          />
          <ContentBody>
            <>
              <DraggableTable
                head={[...blog_table_head.map((val) => val.title)]}
                body={this.state.blogs}
                body_keys={["sno", "title", "action"]}
                onSwap={this.handleSwap}
                onDelete={(id: string) => {
                  this.setState({ modal: true, focus: id });
                }}
                onEdit={(id: any) => {
                  this.props.router.navigate(`/blogs_details/${id}`);
                }}
                pageNo={0}
              />
            </>
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(Blogs);
