import { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import Quill from "quill";
import "react-quill/dist/quill.snow.css";
import UploadImage from "../../../Utils/UploadImage";
import { toast } from "react-hot-toast";
import ImageResize from "quill-image-resize-module-react";
// import { ImageActions } from "@xeger/quill-image-actions";
// import { ImageFormats } from "@xeger/quill-image-formats";
import resizeImageWithAspectRatio from "../../../Utils/resizeImage";

var Parchment = Quill.import("parchment");

const config = {
  scope: Parchment.Scope.BLOCK,
  whitelist: ["1", "1_5", "2", "2_5", "3", "3_5"],
};

var LineHeightClass = new Parchment.Attributor.Class(
  "lineheight",
  "ql-lineHeight",
  config
);

// var LineHeightStyle = new Parchment.Attributor.Style(
//   "lineheight",
//   "line-height",
//   config
// );

Parchment.register(LineHeightClass);
// Parchment.register(LineHeightStyle);

// Quill.register("modules/imageActions", ImageActions);
// Quill.register("modules/imageFormats", ImageFormats);
Quill.register("modules/imageResize", ImageResize);

function TextEditor(props: any) {
  var quillObj: any = useRef();

  const [loading, setLoading] = useState(false);

  function removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  const uploadFiles = (uploadFileObj: any, filename: any, quillObj: any) => {
    setLoading(true);
    // date added to the file name
    let timestamp = new Date();

    let filenameValue = `${
      // @ts-ignore
      removeExtension(filename)
    }_${timestamp.getHours()}${timestamp.getMinutes()}${timestamp.getSeconds()}${timestamp.getMilliseconds()}.${
      // @ts-ignore
      uploadFileObj.type.split("/")[1]
    }`;

    UploadImage({ file: uploadFileObj, name: filenameValue })
      .then(() => {
        let image_url = `${
          "https://travelblogimagesbucket.s3.amazonaws.com/" + filenameValue
        }`;
        const range = quillObj.current.getEditorSelection();
        quillObj.current
          .getEditor()
          .insertEmbed(range.index, "image", image_url);

        setLoading(false);
      })
      .catch(() => {
        toast.error("Something went wrong!!!!!");
        setLoading(false);
      });
  };

  const imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      // @ts-ignore
      var file: any = input.files[0];

      await uploadFiles(file, file.name, quillObj);

      // console.log("we get this file as an output : ", file);

      // var fileName = file.name;

      // const res = await uploadFiles(file, fileName, quillObj);
    };
  };

  const resizeImageWithAspectRatioLocal = (
    img: any,
    width: any,
    maxWidth: any
  ) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Calculate the aspect ratio
    const aspectRatio = img.width / img.height;

    // Ensure the new width is within the maxWidth limit
    if (width > maxWidth) {
      width = maxWidth;
    }

    // Calculate the new height based on the aspect ratio
    const height = width / aspectRatio;

    // Set the canvas dimensions
    canvas.width = width;
    canvas.height = height;

    // Draw the image onto the canvas with the new dimensions
    ctx!.drawImage(img, 0, 0, width, height);

    // Convert the canvas back to an image
    const resizedImage = new Image();
    resizedImage.src = canvas.toDataURL("image/jpeg");

    return resizedImage;
  };

  const modules = useMemo(
    () => ({
      // imageActions: {},
      // imageFormats: {},
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize", "Toolbar"],
        customResizeFunction: resizeImageWithAspectRatioLocal,
      },
      toolbar: {
        container: [
          [
            { header: "1" },
            { header: "2" },
            { header: [3, 4, 5, 6] },
            { font: [] },
          ],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["link", "image", "video"],
          ["clean"],
          [
            {
              lineheight: ["1", "1_5", "2", "2_5", "3", "3_5"],
            },
          ],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "color",
    "background",
    "align",
    "link",
    "image",
    "video",
    "align",
    "float",
    "lineheight",
  ];

  return (
    <div>
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          background: "rgba(0,0,0,.3)",
          top: 0,
          left: 0,
          display: loading ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <div className={"spin"}></div>
      </div>

      <div className="text-editor">
        <ReactQuill
          ref={quillObj}
          style={{
            borderRadius: "15px",
            border: "1px solid grey",
            outline: "none",
            width: "calc(100vw - 130px)",
          }}
          value={props.content}
          onChange={props.handleChange}
          modules={modules}
          formats={formats}
          placeholder="Write Blog Content Here..."
        />
      </div>
    </div>
  );
}

export default TextEditor;
