import styles from "../../../Styles/content.module.css";
import Button from "../Custom/Button";

type props = {
  title: string;
  showBtn1?: boolean;
  showBtn2?: boolean;
  btn1Text?: string;
  btn2Text?: string;
  btn1type?: "outlined" | "filled";
  btn2type?: "outlined" | "filled";
  onClickHandler?: any;
  btn1Name?: string;
  btn2Name?: string;
  customElement?: any;
};

const ContentHead = (props: props) => {
  return (
    <div className={styles.content_container}>
      <h1>{props.title}</h1>
      <div style={{ flexGrow: 1 }}></div>
      {props?.customElement}
      {props?.showBtn2 && (
        <Button
          name={props?.btn2Name}
          type={props?.btn2type}
          text={props?.btn2Text}
          style={{ marginRight: "10px" }}
          clickHandler={props.onClickHandler}
        />
      )}
      {props?.showBtn1 && (
        <Button
          name={props?.btn1Name}
          type={props?.btn1type}
          text={props?.btn1Text}
          clickHandler={props.onClickHandler}
        />
      )}
    </div>
  );
};

export default ContentHead;
