import React, { Component } from "react";
import Content from "../../../Components/Common/Content/Content";
import ContentHead from "../../../Components/Common/Content/ContentHead";
import ContentBody from "../../../Components/Common/Content/ContentBody";
import Input from "../../../Components/Common/Custom/Input";
import TextEditor from "../../../Components/Common/TextEditor/TextEditor";
import InputField from "../../../Components/Common/Custom/InputField";
import withRouter from "../../../Utils/withRouter";
import callApi from "../../../Apis/callApi";
import UploadImage from "../../../Utils/UploadImage";
import { Country, City } from "country-state-city";
import { toast } from "react-hot-toast";
import { animal_dropdown, categories } from "../../../Utils/Data";
import FolderModal from "../../../Components/folderModal";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import axios from "axios";

type props = {
  imageUrl: any;
  imageObject: any;
  title: any;
  offerUrl: string;
  companyUrl: string;
  companyLogoUrl: string;
  companyLogoObject: any;
  companyName: string;
  category: string;
  couponType: string;
  dealType: string;
  validity: string;
  content: any;
  shortDescription: any;
  loading: boolean;
  folderModal: boolean;
  couponCode: any;
  brands: any[];
  brand: any;
  subCategory: string;
  couponSequence: string;
};

class CreateNewCoupon extends Component<any, props> {
  state = {
    imageUrl: "",
    imageObject: "",
    title: "",
    offerUrl: "",
    companyUrl: "",
    companyLogoUrl: "",
    companyLogoObject: "",
    companyName: "",
    category: "",
    subCategory: "",
    couponType: "",
    dealType: "",
    validity: "",
    content: "",
    shortDescription: "",
    loading: false,
    folderModal: false,
    couponCode: "",
    brands: [],
    brand: "",
    couponSequence: "",
  };

  componentDidMount(): void {
    this.getBrands();
    this.generateCoupon();
  }

  getBrands = () => {
    axios({
      url: "https://backend.theholidayfeed.com/api/brands/",
      method: "get",
    })
      .then((res: any) => {
        console.log(res.data.brands);
        this.setState({ brands: res.data.brands });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  generateCoupon = () => {
    callApi({ method: "get", url: "?limit=100000", data: {} })
      .then((res) => {
        if (res.data.coupons.length !== 0) {
          let cpns = res.data.coupons.reverse();

          let large = 0;
          for (let i = 0; i < cpns.length; i++) {
            if (cpns[i]?.couponSequence) {
              if (Number(cpns[i]?.couponSequence.replace("CPN", "")) > large)
                large = Number(cpns[i]?.couponSequence.replace("CPN", ""));
            }
          }

          large += 1;

          this.setState({ couponSequence: "CPN" + large });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  handleCouponImage = (event: any) => {
    this.setState({
      companyLogoUrl: URL.createObjectURL(event.target.files[0]),
      companyLogoObject: event.target.files[0],
    });
  };

  handleImage = (event: any) => {
    this.setState({
      imageUrl: URL.createObjectURL(event.target.files[0]),
      imageObject: event.target.files[0],
    });
  };

  removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  validate = () => {
    return true;
  };

  handleSubmit = (event: any) => {
    if (event.target.name === "btn2") {
      this.props.router.navigate("/coupons");
    } else {
      if (this.validate()) {
        this.setState({ loading: true });

        // adding timestamp to filename

        let filename = getExtendedFileName(this.state.imageObject);

        // checking if need to add image or not

        callApi({ method: "get", url: "", data: {} })
          .then((r: any) => {
            const cpns = r.data.coupons.sort(
              (a: any, b: any) => a.priority - b.priority
            );

            const priority =
              cpns.length === 0 ? 0 : cpns[cpns.length - 1].priority + 1;

            UploadImage({
              file: this.state.imageObject,
              name: "Coupons/" + filename,
            }).then((res) => {
              // when company logo is uploaded from the local machine

              if (!!this.state.companyLogoObject) {
                let timestamp = new Date();

                let logo_filename = getExtendedFileName(
                  this.state.companyLogoObject
                );

                UploadImage({
                  file: this.state.companyLogoObject,
                  name: "Coupons/" + logo_filename,
                }).then((res) => {
                  let coupon_image_url = logo_filename;
                  let data: any = {
                    title: this.state.title,
                    couponType: this.state.couponType,
                    category: this.state.category,
                    subCategory: this.state.subCategory,
                    shortDescription: this.state.shortDescription,
                    longDescription: this.state.content,
                    dealType: this.state.dealType,
                    offerUrl: this.state.offerUrl,
                    // compwebsiteUrl: this.state.companyUrl,
                    couponImage: filename,
                    // compLogo: coupon_image_url,
                    // companyName: this.state.companyName,
                    validity: this.state.validity,
                    couponCode: this.state.couponCode,
                    brandId: this.state.brand,
                    priority,
                    couponSequence: this.state.couponSequence,
                  };

                  console.log(data);

                  if (!data.brandId) {
                    console.log("deleting it");
                    delete data.brandId;
                  }

                  if (!data.couponType) {
                    console.log("deleting it");
                    delete data.couponType;
                  }

                  if (!data.category) {
                    console.log("deleting it");
                    delete data.category;
                  }

                  if (!data.dealType) {
                    console.log("deleting it");
                    delete data.dealType;
                  }

                  callApi({ url: "", data, method: "post" })
                    .then((response) => {
                      this.setState({ loading: false });
                      toast.success("Coupon created successfully.");
                      this.props.router.navigate("/coupons");
                    })
                    .catch((error) => {
                      this.setState({ loading: false });
                      toast.error(
                        "Something went wrong please try again later."
                      );
                    });
                });
              } else {
                // when company logo is selected from already existing ones

                let data: any = {
                  title: this.state.title,
                  couponType: this.state.couponType,
                  category: this.state.category,
                  subCategory: this.state.subCategory,
                  shortDescription: this.state.shortDescription,
                  longDescription: this.state.content,
                  dealType: this.state.dealType,
                  offerUrl: this.state.offerUrl,
                  // compwebsiteUrl: this.state.companyUrl,
                  couponImage: filename,
                  // compLogo: this.state.companyLogoUrl,
                  // companyName: this.state.companyName,
                  validity: this.state.validity,
                  couponCode: this.state.couponCode,
                  brandId: this.state.brand,
                  priority,
                  couponSequence: this.state.couponSequence,
                };

                if (!data.brandId) {
                  console.log("deleting it");
                  delete data.brandId;
                }

                if (!data.couponType) {
                  console.log("deleting it");
                  delete data.couponType;
                }

                if (!data.category) {
                  console.log("deleting it");
                  delete data.category;
                }

                if (!data.dealType) {
                  console.log("deleting it");
                  delete data.dealType;
                }

                callApi({ url: "", data, method: "post" })
                  .then((res) => {
                    this.setState({ loading: false });
                    toast.success("Coupon created successfully.");
                    this.props.router.navigate("/coupons");
                  })
                  .catch((err) => {
                    this.setState({ loading: false });
                    toast.error("Something went wrong please try again later.");
                  });
              }
            });
          })
          .catch((e: any) => {
            toast.error("Error fetching priority");
            this.setState({ loading: false });
          });
      }
    }
  };

  closeFolder = () => {
    this.setState({ folderModal: false });
  };

  selectImage = (val: string) => {
    this.setState({
      companyLogoUrl: val,
    });
  };

  render() {
    return (
      <Content>
        <ContentHead
          title={"Add New Deal "}
          showBtn1={true}
          showBtn2={true}
          btn1type="filled"
          btn2type="outlined"
          btn1Name="btn1"
          btn2Name="btn2"
          btn1Text="Save"
          btn2Text="Discard"
          onClickHandler={(e: any) => {
            this.handleSubmit(e);
          }}
        />
        <FolderModal
          open={this.state.folderModal}
          close={this.closeFolder}
          selectImage={this.selectImage}
        />
        <ContentBody>
          <>
            <div style={{ marginBottom: "20px", color: "grey" }}>
              Deal Sequence : {this.state.couponSequence}
            </div>
            <div
              style={{
                position: "absolute",
                height: "100vh",
                width: "100vw",
                background: "rgba(0,0,0,.3)",
                top: 0,
                left: 0,
                display: this.state.loading ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <div className={"spin"}></div>
            </div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                transform: "translateX(5px)",
                marginBottom: "10px",
              }}
            >
              Deal Image
              {this.state.imageUrl && (
                <span
                  style={{
                    display: "inline-block",
                    background: "grey",
                    borderRadius: "10px",
                    marginLeft: "10px",
                    padding: "10px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ imageUrl: "", imageObject: "" });
                  }}
                >
                  Remove
                </span>
              )}
            </p>

            {!this.state.imageUrl ? (
              <div
                style={{
                  height: "50px",
                  width: "50px",
                  textAlign: "center",
                  background: "transparent",
                  border: "1px dashed grey",
                  borderRadius: "10px",
                  margin: "10px",
                  cursor: "pointer",
                  position: "relative",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                +
                <input
                  type="file"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    cursor: "pointer",
                  }}
                  accept="image/*"
                  onChange={this.handleImage}
                ></input>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  aspectRatio: "16/9",
                  backgroundImage: `url(${this.state.imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  marginBottom: "40px",
                  marginTop: "10px",
                  borderRadius: "15px",
                  position: "relative",
                }}
              ></div>
            )}

            <InputField
              isLabel={true}
              value={this.state.title}
              lable="Deal Title"
              type="text"
              isRequired={false}
              placeholder="Get Flat 20% off"
              onChange={(event: any) => {
                this.setState({ title: event.target.value });
              }}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <InputField
                isLabel={true}
                value={this.state.offerUrl}
                lable="Offer Url"
                type="text"
                isRequired={false}
                placeholder="Enter website url"
                onChange={(event: any) => {
                  this.setState({ offerUrl: event.target.value });
                }}
              />
              <InputField
                isLabel={true}
                value={this.state.brand}
                lable="Select Brand"
                type="select"
                options={[...this.state.brands]}
                accessText="brandName"
                accessValue="_id"
                isRequired={false}
                placeholder="Enter Companies website url"
                onChange={(event: any) => {
                  this.setState({ brand: event.target.value });
                }}
              />
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <InputField
                type="select"
                isLabel={true}
                value={this.state.category}
                lable="Category"
                isRequired={false}
                placeholder="Category"
                options={Object.keys(categories).map((val) => ({
                  name: val,
                  value: val,
                }))}
                accessText={"name"}
                accessValue={"value"}
                onChange={(event: any) => {
                  this.setState({
                    category: event.target.value,
                  });
                }}
              />
              {this.state.category && (
                <InputField
                  type="select"
                  isLabel={true}
                  value={this.state.subCategory}
                  lable="Sub Category"
                  isRequired={false}
                  placeholder="Sub Category"
                  // @ts-ignore
                  options={categories[this.state.category].map((val) => ({
                    name: val,
                    value: val,
                  }))}
                  accessText="name"
                  accessValue="value"
                  onChange={(e: any) => {
                    this.setState({ subCategory: e.target.value });
                  }}
                />
              )}
              <InputField
                type="select"
                isLabel={true}
                value={this.state.couponType}
                lable="Deal Location"
                isRequired={false}
                placeholder="Deal Location"
                options={[
                  { name: "Hero", value: "hero" },
                  { name: "Deal of the day", value: "dealoftheday" },
                  { name: "Trending", value: "trending" },
                  { name: "Preferred", value: "preferred" },
                ]}
                accessText="name"
                accessValue="value"
                onChange={(event: any) => {
                  this.setState({
                    couponType: event.target.value,
                  });
                }}
              />
              {/* </div> */}
              {/* <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            > */}
              <InputField
                type="select"
                isLabel={true}
                value={this.state.dealType}
                lable="Deal Type"
                isRequired={false}
                placeholder="Deal Type"
                options={[
                  { name: "Offer", value: "offer" },
                  { name: "Coupon", value: "coupon" },
                ]}
                accessText={"name"}
                accessValue={"value"}
                onChange={(event: any) => {
                  this.setState({
                    dealType: event.target.value,
                  });
                }}
              />
              <InputField
                type="date"
                isLabel={true}
                value={this.state.validity}
                lable="Validity Date"
                isRequired={false}
                placeholder="Blog Heading"
                options={animal_dropdown}
                accessText={"name"}
                accessValue={"value"}
                onChange={(event: any) => {
                  this.setState({
                    validity: event.target.value,
                  });
                }}
              />
              {/* </div> */}
              {/* <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "20px",
              }}
            > */}
              <InputField
                isLabel={true}
                value={this.state.couponCode}
                lable="Deal Code"
                type="text"
                isRequired={true}
                placeholder="Deal Code"
                onChange={(event: any) => {
                  this.setState({ couponCode: event.target.value });
                }}
              />
            </div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                transform: "translateX(5px)",
                marginTop: "20px",
              }}
            >
              Short Description
            </p>
            <textarea
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "20px",
                borderRadius: "15px",
                border: "1px solid black",
                padding: "20px",
                height: "100px",
              }}
              value={this.state.shortDescription}
              placeholder={"Add basic details about deal"}
              onChange={(event: any) => {
                this.setState({ shortDescription: event.target.value });
              }}
            />
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                transform: "translateX(5px)",
                marginBottom: "10px",
              }}
            >
              Long Description
            </p>
            <TextEditor
              content={this.state.content}
              handleChange={(
                value: any,
                delta: any,
                source: any,
                editor: any
              ) => {
                console.log(value);
                this.setState({ content: value });
              }}
            />
          </>
        </ContentBody>
      </Content>
    );
  }
}

export default withRouter(CreateNewCoupon);
