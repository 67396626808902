import React, { useEffect } from "react";
import Content from "../../Components/Common/Content/Content";
import ContentHead from "../../Components/Common/Content/ContentHead";
import ContentBody from "../../Components/Common/Content/ContentBody";
import InputField from "../../Components/Common/Custom/InputField";
import styles from "./brands.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { getExtendedFileName } from "../../Utils/ImageHelper";
import UploadImage from "../../Utils/UploadImage";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import UploadImageBrand from "../../Utils/UploadImageBrand";

const AddUpdateBrands = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [brand_name, setBrandName] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [logo, setLogo] = React.useState({ url: "", obj: null });
  const [description, setDescription] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (param.id) {
      getData();
    } else {
      setLoading(false);
    }
  }, []);

  const getData = () => {
    axios({
      url: "https://backend.theholidayfeed.com/api/brands/" + param.id,
      method: "GET",
    })
      .then((res: any) => {
        console.log(res.data.brand);
        setBrandName(res.data.brand.brandName);
        setUrl(res.data.brand.url);
        setLogo({ url: res.data.brand.brandLogo, obj: null });
        setDescription(res.data.brand.description);
        setLoading(false);
      })
      .catch(() => {});
  };

  const handleImg = (e: any) => {
    if (e.target.files[0]) {
      setLogo({
        url: URL.createObjectURL(e.target.files[0]),
        obj: e.target.files[0],
      });
    }
  };

  const validate = () => {
    let text = "";

    // check logo
    if (!logo.url) {
      text = "Please upload logo";
    }

    // check brand name
    if (!brand_name && !text) {
      text = "Please enter brand name";
    }

    // check url
    if (!url && !text) {
      text = "Please enter url";
    }

    // check description
    if (!description && !text) {
      text = "Please enter description";
    }

    if (!text) {
      return true;
    } else {
      toast.error(text);
      return false;
    }
  };

  const uploadImg = async () => {
    if (logo.obj) {
      let filename = getExtendedFileName(logo.obj);
      try {
        const res = await UploadImageBrand({ file: logo.obj, name: filename });
        return filename;
      } catch (err) {}
    } else {
      return logo.url;
    }
  };

  const addBrand = async () => {
    if (true) {
      setLoading(true);
      let filename = await uploadImg();

      let data = {
        brandName: brand_name,
        url: url,
        brandLogo: filename,
        description,
      };

      axios({
        url: param?.id
          ? "https://backend.theholidayfeed.com/api/brands/" + param.id
          : "https://backend.theholidayfeed.com/api/brands",
        method: param?.id ? "PATCH" : "POST",
        data,
      })
        .then((res: any) => {
          setLoading(false);
          toast.success(
            param?.id
              ? "Brand updated successfully"
              : "Brand added successfully"
          );
          navigate("/brands");
        })
        .catch((err: any) => {
          setLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <>
      {loading && (
        <div className={"loading"}>
          <span className="spin"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title="Add Brand"
          showBtn1={true}
          showBtn2={true}
          btn1Name="btn1"
          btn2Name="btn2"
          btn1type="filled"
          btn2type="outlined"
          btn1Text="Save"
          btn2Text="Cancel"
          onClickHandler={(e: any) => {
            if (e.target.name === "btn1") {
              addBrand();
            } else {
              navigate("/brands");
            }
          }}
        />
        <ContentBody>
          <div className={styles.grid_1}>
            <div className={styles.logo_container}>
              <p>Brand Logo</p>
              {logo?.url ? (
                <div className={styles.logo}>
                  <img
                    src={
                      logo?.obj
                        ? logo?.url
                        : process.env.REACT_APP_IMAGE_BASE_FAST_LINK + logo?.url
                    }
                    alt="logo"
                  />
                  <div
                    className={styles.logo_close}
                    onClick={() => {
                      setLogo({ url: "", obj: null });
                    }}
                  >
                    <FontAwesomeIcon icon={faClose} color="white" />
                  </div>
                </div>
              ) : (
                <div className={styles.logo_field}>
                  <p>+</p>
                  <input type="file" accept="image/*" onChange={handleImg} />
                </div>
              )}
            </div>
            <div className={styles.grid_2}>
              <InputField
                isLabel={true}
                value={brand_name}
                lable="Brand Name"
                type="text"
                isRequired={true}
                placeholder="Enter brand name"
                onChange={(e: any) => {
                  setBrandName(e.target.value);
                }}
              />
              <InputField
                isLabel={true}
                value={url}
                lable="Brand Url"
                type="text"
                isRequired={true}
                placeholder="https://www.brandurl.com"
                onChange={(e: any) => {
                  setUrl(e.target.value);
                }}
              />
            </div>
            <div className={styles.textArea}>
              <p>Description *</p>
              <textarea
                placeholder="Enter Description"
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
              />
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default AddUpdateBrands;
