import { useNavigate } from "react-router-dom";
import Content from "../../Components/Common/Content/Content";
import ContentBody from "../../Components/Common/Content/ContentBody";
import ContentHead from "../../Components/Common/Content/ContentHead";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import Table from "../../Components/Common/Table/Table";
import Modal from "../../Components/Modal";

const Ad = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [focus, setFocus] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      url: "https://backend.theholidayfeed.com/api/advertisements/?identifier=thecouponsfeed.com",
      method: "get",
    })
      .then((res) => {
        setData(res.data.advertisements);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("No Advertisements found.");
        setLoading(false);
      });
  };

  const handleDelete = (id: any) => {
    // delete the blog here
    axios({
      method: "delete",
      url: `https://backend.theholidayfeed.com/api/advertisements/${id}`,
      headers: {
        Authorization: localStorage.getItem("cpnblg_token"),
      },
    })
      .then((res) => {
        getData();
      })
      .catch((errror) => {
        toast.error(errror.response.data.message);
      });
  };

  const handleAction = () => {
    // this.setState({ modal: false });
    setModal(false);
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: "https://backend.theholidayfeed.com/api/advertisements/" + focus,
          method: "delete",
          headers: {
            Authorization: localStorage.getItem("cpnblg_token"),
          },
        })
          .then((res: any) => {
            setFocus("");
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res: any) => {
          getData();
          return "Deleted Successfully!";
        },
        error: (err: any) => {
          return "Error in deleting the product.";
        },
      }
    );
  };

  return (
    <>
      {/* loader */}
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          background: "rgba(0,0,0,.3)",
          top: 0,
          left: 0,
          display: loading ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <div className={"spin"}></div>
      </div>

      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          // this.setState({ modal: false, focus: "" });
          setModal(false);
          setFocus("");
        }}
        onBtn2Click={handleAction}
      />

      <Content>
        <ContentHead
          title="Advertisement"
          showBtn1={true}
          btn1Name="btn1"
          btn1Text="Add New"
          btn1type="filled"
          onClickHandler={() => navigate("/ads/create_new_ad")}
        />
        <ContentBody>
          <Table
            head={[
              { title: "S.NO." },
              { title: "Title" },
              { title: "Brand" },
              { title: "Category" },
              { title: "Clicks" },

              { title: "" },
            ]}
            body={data}
            body_keys={["title", "brand", "category", "clicks", "action"]}
            number={true}
            onRowClick={(val: any) => {
              navigate(`/ads/update-ad/${val._id}`);
            }}
            action_handler={(id: any) => {
              setModal(true);
              setFocus(id);
            }}
          />
        </ContentBody>
      </Content>
    </>
  );
};

export default Ad;
