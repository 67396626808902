import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faTableColumns,
  faBox,
  faBlog,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";

type ISidebarData = { title: string; icon: IconProp; link: string };
type IBlogTableHead = { title: string };

export const sidebar_data: ISidebarData[] = [
  { title: "Coupons", icon: faMailBulk, link: "/coupons" },
  { title: "Blogs", icon: faBlog, link: "/blogs" },
  { title: "Ads", icon: "ad", link: "/ads" },
  { title: "Brands", icon: faCopyright, link: "/brands" },
];

export const coupon_table_head: IBlogTableHead[] = [
  { title: "S.No" },
  { title: "Deal Sequence" },
  { title: "Deal Title" },
  { title: "Deal Type" },
  { title: "Category" },
  { title: "SubCategory" },
  { title: "Brand" },
  { title: "Actions" },
];

export const blog_table_head: IBlogTableHead[] = [
  { title: "S.No" },
  { title: "Blog Title" },
  { title: "Actions" },
];

export const brand_table_head = [
  { title: "S.No" },
  { title: "Brand Name" },
  { title: "Url" },
  { title: "Actions" },
];

export const animal_dropdown = [
  { name: "Dog", value: "dog" },
  { name: "Cat", value: "cat" },
  { name: "Fish", value: "fish" },
  { name: "Horse", value: "horse" },
  { name: "Rabit", value: "rabit" },
  { name: "Hamster", value: "hamster" },
  { name: "Cows", value: "cows" },
  { name: "Sheeps", value: "sheeps" },
  { name: "Goat", value: "goat" },
  { name: "Birds", value: "birds" },
  { name: "Guinea Pigs", value: "guinea_pig" },
  { name: "Gerbils", value: "gerbils" },
  { name: "Ferrets", value: "ferrets" },
  { name: "Hedgehogs", value: "hedgehogs" },
  { name: "Pet Rats", value: "pet_rats" },
  { name: "Turtle", value: "turtle" },
  { name: "Snake", value: "snake" },
  { name: "Geckos", value: "geckos" },
  { name: "Frogs", value: "frogs" },
  { name: "Bearded Dragons", value: "bearded_dragons" },
  { name: "Monkey", value: "monkey" },
  { name: "Mule", value: "mule" },
  { name: "Tortoise", value: "tortoise" },
];

export const form_data = [
  // { label: "Title", type: "text" },
  // { label: "Category", type: "select" },
  // { label: "Coupon Type", type: "select" },
  // { label: "Deal Type", type: "select" },
  // { label: "Short Description", type: "textarea" },
  // { label: "Long Description", type: "textEditor" },
  // { label: "Offer Url", type: "text" },
  // { label: "Company Website Url", type: "text" },
  // { label: "Company Name", type: "text" },
  // { label: "Company Logo", type: "text" },
  { label: "Coupon Code", type: "text" },
  // { label: "Coupon Image", type: "img" },
  // { label: "Validity Date", type: "date" },
  { label: "Active", type: "Checkbox" },
];

export const categories = {
  "New User Offers": ["Welcome Offers", "Exclusive Offers For you"],
  Fashion: [
    "Clothing & Footwear",
    "Watches",
    "Lingerie",
    "Accessories & Jewellery",
  ],
  "Health & Beauty": [
    "Beauty & Personal Care",
    "Health Supplements",
    "Pharmacy",
  ],
  Travel: [
    "Domestic Hotels",
    "Domestic Flights",
    "International Hotels",
    "International Flights",
    "Car Rental",
    "Travel Accessories",
  ],
  Electronics: [
    "Mobile Phones",
    "Electronic Accessories",
    "TVs & Large Appliances",
    "Laptops & Tablets",
  ],
  "Home & Kitchen": ["Home & Kitchen Appliances", "Home & Kitchen Products"],
  "Food & Grocery": ["Grocery", "Food Delivery"],
  "More Categories": [
    "Baby & Kids",
    "Banking & Finance",
    "Department Stores",
    "Domain & Hosting",
    "Entertainment",
    "Gifting",
  ],
  "Sports and Fitness": [
    "Sports Equipments",
    "Fitness Equipments",
    "Gym Equipments",
  ],
  "Digital services": ["OTT", "Music", "VPN"],
  "Automotive & accessories": [
    "Car accessories",
    "Bike accessories",
    "Cycle accessories",
    "Truck & Tractor accessories",
  ],
  "Kitchen Appliances and Essentials": [
    "Breakfast Appliances",
    "Cooking Appliances",
  ],
  Furniture: ["Home furniture", "Office furniture", "Kitchen furniture"],
  Pets: ["Pet care", "Pet services", "Pet medication"],
};
