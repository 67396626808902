import { useEffect, useState } from "react";
import styles from "../Styles/folderModal.module.css";
import callApi from "../Apis/callApi";

const FolderModal = ({
	open,
	close,
	selectImage,
}: {
	open: boolean;
	close: any;
	selectImage: any;
}) => {
	const [images, setImages]: [any, any] = useState([]);

	useEffect(() => {
		callApi({ method: "get", url: "getcompanylogo", data: {} })
			.then((res) => {
				setImages(res.data);
			})
			.catch((err) => {
				console.log("this is the error : ", err);
			});
	}, []);

	return open ? (
		<div className={styles.container}>
			<div className={styles.folder_card}>
				{/* header section */}
				<div className={styles.folder_header}>
					<p>Logo Images</p>
					<div style={{ flexGrow: 1 }}></div>
					<p
						onClick={() => {
							close();
						}}
					>
						X
					</p>
				</div>

				{/* images */}
				<div className={styles.image_container}>
					{images.map((val: any, index: any) => {
						return (
							<div
								style={{
									width: "100%",
									aspectRatio: "1/1",
									background: `url("${
										process.env.REACT_APP_IMAGE_BASE_FAST_LINK +
										"Coupons/" +
										val
									}")`,
									backgroundPosition: "center",
									backgroundSize: "cover",
									cursor: "pointer",
								}}
								onClick={() => {
									selectImage(val);
									close();
								}}
							></div>
						);
					})}
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};

export default FolderModal;
